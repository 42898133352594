// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m["default"] || m;
};

exports.components = {
  "component---deck-mdx": function componentDeckMdx() {
    return import("./../../../deck.mdx"
    /* webpackChunkName: "component---deck-mdx" */
    );
  }
};